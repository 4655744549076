/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$blue:    #003865;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #0a8927;
$teal:    #20c997;
$cyan:    #17a2b8;

$mn-green:   #78BE21;

$theme-colors: (
  primary: $blue,
  secondary: #6c757d,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $black
);

$navbar-dark-color: rgba($mn-green, 1);
$navbar-dark-hover-color: rgba($mn-green, .8);
$font-size-base: 18;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.17rem;
$h4-font-size: 1rem;
$h5-font-size: 0.83rem;
$h6-font-size: 0.75rem;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
