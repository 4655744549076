/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

 body {
  background-color: #F3F6F7;
}

/* Tables */

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  padding-left: 22px;
}

th[sortable].desc:before, th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
  background-size: 24px;
  width: 22px;
  height: 24px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.table-row-link {
  cursor: pointer;
}

.form-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

#wells table {
  background-color: #ffffff;
  margin-bottom: inherit;
  text-align: center;
}

#questionnaires-table input {
  cursor: pointer;
}

#edit-answers-list-table {
  width: 1280px;
}

@media screen and (min-width: 1360px) {
  #edit-answers-list-table {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  #edit-answers-findings-list-table {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  #edit-answers-findings-list-table {
    font-size: 12px;
  }
}

/* Collapsible panel */

.panel-collapsible-header {
  cursor: pointer;
}

/* Forms */

#basic-details-form .form-group {
  margin-bottom: 5px;
}

#basic-details-form .form-group label,
#contact-mailing-form .form-group label,
#note-form .form-group label,
#questionnaire-form .form-group label,
#form-add-question-category .form-group label,
#form-delete-question-category .form-group label,
#question-form .form-group label {
  font-weight: bold;
}

#basic-details-form .card *, #water-system-contacts .card *,
#contact-mailing-form *, #water-operator-form *,
#notes .card *, #note-form *,
#water-system-site-visits .card * {
  font-size: 1rem;
}

fieldset.form-check {
  padding-left: 0;
}

fieldset.form-check legend {
  font-weight: bold;
}

fieldset.form-check input.form-check-input {
  margin-left: 0;
  position: inherit;
}

fieldset.form-check label.form-check-label {
  padding: 0 20px 0 5px;
}

fieldset.form-group legend {
  font-weight: bold;
}

.ng-touched.ng-invalid {
  @extend .is-invalid;
}

#add-question-type {
  height: 100%;
  width: 100%;
}

#add-question-display-name {
  height: 100%;
  width: 100%;
}

.answer-input-text {
  height: 100%;
  width: 100%;
}

#questionnaire-form .ng-untouched.ng-invalid,
#questionnaire-form .ng-touched.ng-invalid,
#question-form .ng-untouched.ng-invalid,
#question-form .ng-touched.ng-invalid,
#survey-form .ng-invalid,
#survey-form .custom-file-input.ng-invalid,
#facility-form .ng-untouched.ng-invalid,
#facility-form .ng-touched.ng-invalid {
  border-color: #dc3545;
}

.required {
  color: #dc3545;
}

.custom-file-label {
  height: fit-content;
}

.dropdown-menu.show {
  max-height: 100px;
  overflow: auto;
}

/* Modals */

.modal {
  z-index: 99999;
}

.element-code-modal div.modal-dialog.modal-dialog-centered.modal-xl {
  display: flex;
  max-height: calc(100% - 3.5rem);
}

.element-code-modal div.modal-dialog.modal-dialog-centered.modal-xl div.modal-content {
  max-height: calc(100vh - 3.5rem);
  overflow: hidden;
}

.element-code-modal div.modal-dialog.modal-dialog-centered.modal-xl div.modal-content div.modal-body {
  overflow-y: auto;
}

.modal-body {
  white-space: pre-line;
}

/* Capture Picture */
#visit-video {
  border: 1px solid black;
  box-shadow: 2px 2px 3px black;
  width: 320px;
  height: 240px;
}

#visit-photo {
  border: 1px solid black;
  box-shadow: 2px 2px 3px black;
  width: 320px;
  height: 240px;
  vertical-align: top;
}

#visit-canvas {
  display: none;
}

.visit-camera {
  width: 340px;
  display: inline-block;
}

.visit-output {
  width: 340px;
  display: inline-block;
}

#visit-startbutton {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: $blue;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 1.0);
}

.visit-capture-picture-modal {
  text-align: center;
}

/* Misc */

.pre-line-breaker {
  white-space: pre-line;
}

.display-flex {
  display: flex;
}

@media screen and (min-width: 769px) {
  .display-flex {
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .display-flex {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}

.btn:disabled,
input:disabled,
select:disabled {
  cursor: not-allowed;
}

.btn-checkout-widget-disabled {
  opacity: 0.3 !important;
}

.fa-trash-alt {
  color: #B60000;
}

.white-space-break-spaces {
  white-space: break-spaces;
  word-wrap: break-word;
}

#questionnaire-form .fa-minus-square {
  color: #B60000;
}

#questionnaire-form .fa-plus-square {
  color: $mn-green;
}

#question-form .fa-minus-square {
  color: #B60000;
}

.ngb-toasts {
	margin: 8em 2em 0.5em 0.5em;
	position: fixed;
	right: 0;
  top: 0;
  width: 300px;
	z-index: 99999;
}

@media screen and (max-height: 500px) {
  .ngb-toasts {
    margin: 0.5em;
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 99999;
  }
}

.bg-success.fade.show.toast .toast-body {
  color: white;
}

.custom-file-label {
  border: dotted 2px $gray-500;
}

.custom-file-label::after {
  display: none;
}

input[type='checkbox'] {
  width: 15px;
  height: 15px;
}

.collapsible-panel-header-expand-collapse-icon {
  float: right;
  line-height: 1.5;
}

@media screen and (max-width: 535px) {
  .collapsible-panel-header-expand-collapse-icon {
    float: none;
  }
}

.btn-outline-secondary {
  background-color: white;
}
